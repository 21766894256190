<template>
  <main>
    Digitalisierung
  </main>
</template>

<script>
export default {
  name: 'PageDigitization',
}
</script>
